import './App.css';
import { useState } from 'react';

function App() {
  let [results, setResults] = useState([]);

  const handleClick = () => {
    const arr = [];
    for (let i = 1; i <= 5; i++) {
      const maxValue = document.getElementById(`race${i}`).value;
      const randamValueMath = Math.floor(Math.random() * maxValue + 1);
      arr.push(randamValueMath);
    }
    const tmp = [...results, arr];
    setResults(tmp);
  };

  return (
    <div className="App">
      <h1 className="">win5乱数発生器</h1>
      <div>
        <form>
          <label htmlFor="race1">第一レース</label>
          <input type="number" id="race1" defaultValue={10} />

          <label htmlFor="race2">第二レース</label>
          <input type="number" id="race2" defaultValue={10} />

          <label htmlFor="race3">第三レース</label>
          <input type="number" id="race3" defaultValue={10} />

          <label htmlFor="race4">第四レース</label>
          <input type="number" id="race4" defaultValue={10} />

          <label htmlFor="race5">第五レース</label>
          <input type="number" id="race5" defaultValue={10} />

          <div>
            <button type="button" onClick={handleClick}>
              抽選
            </button>
            <button type="button" onClick={() => setResults([])}>
              リセット
            </button>
          </div>
        </form>
      </div>
      <div>
        <ul>
          {results.map((result, index) => (
            <li key={index}>{result.join(',')}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default App;
